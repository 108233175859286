.serve-container {
    min-height: 80vh;
    width: 100%;
    border-radius: 0%;
    background-color: rgb(92, 168, 255);
    background-image: url(../assets/serve_background.jpeg);
    background-size: cover;
    background-position: 50%;
}
  
.serve-container h1 {
    margin: 0 auto;
    font-size: 35px;
  }
  
  .serve-section {
    margin-left: 8%;
  }
  
  .serve-section h2 {
    text-align: left;
    margin-bottom: 15px;
  }

  .serve-section p {
    text-align: left;
    line-height: 25px;
    width: 80%;
  }
  
  .serve-container .screenshot {
    margin: auto 0px;
    position: inherit;
    height: 15%;
    width: 35%;
    /* background-color: rgba(0, 0, 0, 0.322); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease, transform 0.2s ease;
    content: url(../assets/serve_image.jpeg);
  }
  
  .serve-container .download {
    position: inherit;
    height: 80px;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.322);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20%;
    margin-bottom: 20px;
    margin-top: 30px;
    align-items: center;
    transition: background 0.3s ease, transform 0.2s ease;
  }
  
  .serve-container .download:hover {
    background-color: rgba(255, 255, 255, 0.096);
    transform: scale(1.1);
  }
  
  .serve-container .download .icon {
    position: relative;
    font-size: 40px;
  }
  
  .serve-container .download h2 {
    position: relative;
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    /* font-family: 'Open Sans'; */
    text-align: center;
  }

  @media (max-width: 1020px) {
      .serve-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 80vh;
        margin-top: 100px;
        padding: 10px 10px 50px 10px;
      }

      .serve-section {
        margin-left: 0%;
      }

      .serve-section h2 {
        text-align: center;
        margin-bottom: 15px;
      }
    
      .serve-section p {
        margin: 0px auto;
        text-align: center;
        line-height: 25px;
        width: 90%;
      }

      .serve-container .screenshot {
        height: 25%;
        width: 45%;
      }
  }
  
  @media (max-width: 820px) {
    .serve-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 80vh;
        margin-top: 100px;
        padding: 10px 10px 50px 10px;
      }

      .serve-section {
        margin-left: 0%;
      }

      .serve-section h2 {
        text-align: center;
        margin-bottom: 15px;
      }
    
      .serve-section p {
        margin: 0px auto;
        text-align: center;
        line-height: 25px;
        width: 90%;
      }

      .serve-container .screenshot {
        height: 25%;
        width: 45%;
      }
  }
  