.gallery-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 680px;
    width: 100%;
    padding: 10px 10px 10px 10px;
    overflow: hidden;
    border-radius: 0%;
  }

  .gallery-title-container {
    height: 80px;
    border-bottom: 3px solid #63449c;
  }

  .gallery-title-container h2 {
    margin-top: 30px;
    color: black;
    font-size: 28px;
  }

  .gallery-section {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 600px;
  }

  .images-section {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 720px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    user-select: none;
    scroll-behavior: smooth;
    padding: 30px 0 0 0;
  }

  .image-container {
    position: relative;
    height: 550px;
    min-width: 40%;
    background-color: rgba(14, 14, 14, 0.285);
    border-radius: 20px;
    margin: 20px 20px 0 0;
  }

  .image-info-gallery {
    width: 100%;
    height: 100px;
    background-color: rgba(44, 44, 44, 0.764);
    bottom: 0;
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  #img1 {
    background-image: url(../assets/gallery/fuyao.png);
    background-size: cover;
    background-position: -200px 0px;
  }

  #img2 {
    background-image: url(../assets/gallery/jinko.png);
    background-size: cover;
    background-position: -50px;
  }

  #img3 {
    background-image: url(../assets/gallery/jushi.png);
    background-size: cover;
    background-position: -180px 0px;
  }

  #img4 {
    background-image: url(../assets/gallery/hailiang.png);
    background-size: cover;
    background-position: -50px 0px;
  }

  #img5 {
    background-image: url(../assets/gallery/minth.png);
    background-size: cover;
    background-position: -50px 0px;
  }

  #img6 {
    background-image: url(../assets/gallery/yinlun.png);
    background-size: cover;
    background-position: -300px 0px;
  }

  #arrow-back {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 200px;
      margin-right: 2%;
  }

  #arrow-forward {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 200px;
    margin-left: 2%;
}

button {
    border: none;
    background: none;
}

.buttonIcon {
    fill: black;
}

.buttonIcon:hover {
    cursor: pointer;
    fill: rgb(150, 30, 150);
}

@media (max-width: 1120px) {
    .image-container {
        height: 550px;
        min-width: 50%;
      }
}

@media (max-width: 1080px) {
    .image-container {
        height: 550px;
        min-width: 60%;
      }
}

@media (max-width: 900px) {
    .image-container {
        height: 550px;
        min-width: 80%;
      }
}

@media (max-width: 672px) {
    .image-container {
        height: 480px;
        min-width: 90%;
      }
}

@media (max-width: 520px) {
    .image-container {
        height: 480px;
        min-width: 100%;
      }
}