  #about {
      padding-top: 80px;
  }
  
  .about-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 70vh;
    width: 100%;
    padding: 10px 50px 10px 50px;
    overflow: hidden;
    border-radius: 0%;
  }

  .about-container h2 {
    margin-top: 50px;
    font-size: 30px;
    color: black;
    border-bottom: 3px solid #63449c;
    animation: fading-in 1s ease 1 forwards;
  }
  
  .about-container p {
    margin-top: 40px;
    line-height: 30px;
    width: 80%;
    text-align: center;
    font-size: 18px;
    color: black;
    animation: fading-in 1.25s ease 1 forwards;
  }

  @keyframes fading-in {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @media (max-width: 1236px) {
    .half h2 {
      margin-top: 0%;
    }
  }
  
  @media (max-width: 1084px) {
    .about-container {
        min-height: 70vh;
    }

    .about-container p {
        line-height: 24px;
        font-size: 18px;
    }
  }
  
  @media (max-width: 820px) {
    #about {
      padding-top: 80px;
    }
  
    .about-container {
        min-height: 70vh;
    }

    .about-container p {
        line-height: 20px;
        font-size: 18px;
    }
  }
  
  @media (max-width: 500px) {
    #about {
      padding-top: 80px;
    }

    .about-container {
        min-height: 70vh;
    }

    .about-container p {
        line-height: 16px;
        font-size: 14px;
    }
  }
  
  @media (max-width: 400px) {
    #about {
      padding-top: 80px;
    }
  
    .about-container {
        min-height: 80vh;
    }

    .about-container p {
        font-size: 14px;
    }
  }
  