.extra-container {
    margin: 40px 0 0 0;
    width: 100%;
    min-height: 40vh;
    background-color: rgba(0, 0, 0, 0.624);
    border-radius: 0%;
    /* background-image: url(../assets/extraBackground.png); */
    background-size: cover;
    background-position: 0px -300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.extra-container p {
    width: 80%;
    text-align: center;
}