.home-container {
    position: relative;
    height: 90vh;
    width: 100%;
    border-radius: 0%;
    padding: 50px 40px;
    margin-top: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/home_background3.jpeg);
    background-position: 80% 35%;
  }

  #background-image {
    background-color: rgba(58, 58, 58, 0.555);
    position: absolute;
    height: 90vh;
    width: 100%;
  }
  
  @keyframes fade-in2 {
    to {
      transform: translate(0);
      opacity: 1;
    }
  }
  
  .home-container h2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    max-width: 60%;
    text-align: center;
    color: white;
    animation: fading-in 1s ease 1 forwards;
  }

  #exploreBtn {
    /* font-family: "Andale Mono", monospace; */
    font-weight: bold;
    margin-top: 50px;
    display: flex;
    width: 150px;
    height: 50px;
    /* background-color: orangered; */
    background-color: #009541;
    text-align: center;
    align-items: center;
    justify-content: center;
    animation: fading-in 1.5s ease 1 forwards;
  }

  @keyframes fading-in {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  #exploreBtn:hover {
    /* background-color: rgb(190, 51, 0); */
    background-color: #11512c;
  }

  .scroll-down {
    transform: rotate(90deg);
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
  
  .scroll-down hr {
    background-color: blue;
    width: 20px;
  }
  
  .scroll-down h5 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 100;
  }
  
  .scroll-down .scroll {
    transform: rotate(-90deg);
    font-size: 20px;
  }
  
  @media (max-width: 820px) {
    .home-container {
      position: relative;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  
    .home-container img {
      position: absolute;
      height: 250px;
      width: 250px;
      border-radius: 50%;
      border: 3px solid rgb(108, 161, 197);
      transform: translate(-200px);
      opacity: 0;
      animation: fade-in 1s ease 1 0.5s forwards;
    }
  
    .home-container h2 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-top: 100px;
    }
  
    .home-container h2 span {
      font-size: 25px;
      opacity: 0.8;
      padding-bottom: 5px;
      border-bottom: 2px solid var(--color-pri);
      font-family: "Open Sans Condensed", sans-serif;
    }
  
    .home-container h2 p {
      font-size: 25px;
      font-weight: 100;
      font-family: "Open Sans", sans-serif;
    }
  }
  
  @media (max-width: 500px) {
    .home-container {
      position: relative;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    
    .home-container img {
      position: absolute;
      height: 200px;
      width: 200px;
      border-radius: 50%;
      border: 3px solid rgb(108, 161, 197);
      transform: translate(-200px);
      opacity: 0;
      animation: fade-in 1s ease 1 0.5s forwards;
      margin-top: -150px;
    }
  
    .home-container h2 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-top: 50px;
    }
  
    .home-container h2 span {
      font-size: 22px;
      opacity: 0.8;
      padding-bottom: 5px;
      border-bottom: 2px solid var(--color-pri);
      font-family: "Open Sans Condensed", sans-serif;
    }
  
    .home-container h2 p {
      font-size: 18px;
      font-weight: 100;
      font-family: "Open Sans", sans-serif;
    }
    .scroll-down {
      transform: rotate(90deg);
      position: absolute;
      right: -70px;
      top: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
  }
  
  @media (max-width: 400px) {
    .home-container {
      position: relative;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  
    .home-container img {
      position: absolute;
      height: 185px;
      width: 185px;
      border-radius: 50%;
      border: 3px solid rgb(108, 161, 197);
      transform: translate(-200px);
      opacity: 0;
      animation: fade-in 1s ease 1 0.5s forwards;
    }
  
    .home-container h2 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-top: 250px;
    }
  
    .home-container h2 span {
      font-size: 22px;
      opacity: 0.8;
      padding-bottom: 5px;
      border-bottom: 2px solid var(--color-pri);
      font-family: "Open Sans Condensed", sans-serif;
    }
  
    .home-container h2 p {
      font-size: 18px;
      font-weight: 100;
      font-family: "Open Sans", sans-serif;
    }
  }
  