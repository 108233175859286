@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  z-index: 1;
}

body {
  background: rgb(212, 212, 212);
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  -webkit-animation: move 1s linear infinite forwards;
          animation: move 1s linear infinite forwards;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0;
}

:root {
  --color-pri: rgb(82, 188, 250);
  --color-pri-light: rgba(82, 188, 250, 0.445);
  --color-sec: rgb(172, 17, 233);
  --color-sec-light: rgba(172, 17, 233, 0.274);

  --pc-width: 75%;
  --tab-width: 90%;
  --phone-width: 100%;
}

.container {
  border-radius: 10px;
  width: 75%;
  width: var(--pc-width);
  color: var(--text-pri);
  padding: 10px 40px;
  margin: 5px auto;
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .container {
    width: 100%;
    width: var(--phone-width);
  }
}


.home-container {
    position: relative;
    height: 90vh;
    width: 100%;
    border-radius: 0%;
    padding: 50px 40px;
    margin-top: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(/static/media/home_background3.41a57518.jpeg);
    background-position: 80% 35%;
  }

  #background-image {
    background-color: rgba(58, 58, 58, 0.555);
    position: absolute;
    height: 90vh;
    width: 100%;
  }
  
  @-webkit-keyframes fade-in2 {
    to {
      -webkit-transform: translate(0);
              transform: translate(0);
      opacity: 1;
    }
  }
  
  @keyframes fade-in2 {
    to {
      -webkit-transform: translate(0);
              transform: translate(0);
      opacity: 1;
    }
  }
  
  .home-container h2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
    max-width: 60%;
    text-align: center;
    color: white;
    -webkit-animation: fading-in 1s ease 1 forwards;
            animation: fading-in 1s ease 1 forwards;
  }

  #exploreBtn {
    /* font-family: "Andale Mono", monospace; */
    font-weight: bold;
    margin-top: 50px;
    display: flex;
    width: 150px;
    height: 50px;
    /* background-color: orangered; */
    background-color: #009541;
    text-align: center;
    align-items: center;
    justify-content: center;
    -webkit-animation: fading-in 1.5s ease 1 forwards;
            animation: fading-in 1.5s ease 1 forwards;
  }

  @-webkit-keyframes fading-in {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes fading-in {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      opacity: 1;
    }
  }

  #exploreBtn:hover {
    /* background-color: rgb(190, 51, 0); */
    background-color: #11512c;
  }

  .scroll-down {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    cursor: pointer;
  }
  
  .scroll-down hr {
    background-color: blue;
    width: 20px;
  }
  
  .scroll-down h5 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 100;
  }
  
  .scroll-down .scroll {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    font-size: 20px;
  }
  
  @media (max-width: 820px) {
    .home-container {
      position: relative;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  
    .home-container img {
      position: absolute;
      height: 250px;
      width: 250px;
      border-radius: 50%;
      border: 3px solid rgb(108, 161, 197);
      -webkit-transform: translate(-200px);
              transform: translate(-200px);
      opacity: 0;
      -webkit-animation: fade-in 1s ease 1 0.5s forwards;
              animation: fade-in 1s ease 1 0.5s forwards;
    }
  
    .home-container h2 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-top: 100px;
    }
  
    .home-container h2 span {
      font-size: 25px;
      opacity: 0.8;
      padding-bottom: 5px;
      border-bottom: 2px solid var(--color-pri);
      font-family: "Open Sans Condensed", sans-serif;
    }
  
    .home-container h2 p {
      font-size: 25px;
      font-weight: 100;
      font-family: "Open Sans", sans-serif;
    }
  }
  
  @media (max-width: 500px) {
    .home-container {
      position: relative;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    
    .home-container img {
      position: absolute;
      height: 200px;
      width: 200px;
      border-radius: 50%;
      border: 3px solid rgb(108, 161, 197);
      -webkit-transform: translate(-200px);
              transform: translate(-200px);
      opacity: 0;
      -webkit-animation: fade-in 1s ease 1 0.5s forwards;
              animation: fade-in 1s ease 1 0.5s forwards;
      margin-top: -150px;
    }
  
    .home-container h2 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-top: 50px;
    }
  
    .home-container h2 span {
      font-size: 22px;
      opacity: 0.8;
      padding-bottom: 5px;
      border-bottom: 2px solid var(--color-pri);
      font-family: "Open Sans Condensed", sans-serif;
    }
  
    .home-container h2 p {
      font-size: 18px;
      font-weight: 100;
      font-family: "Open Sans", sans-serif;
    }
    .scroll-down {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      position: absolute;
      right: -70px;
      top: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
      cursor: pointer;
    }
  }
  
  @media (max-width: 400px) {
    .home-container {
      position: relative;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
  
    .home-container img {
      position: absolute;
      height: 185px;
      width: 185px;
      border-radius: 50%;
      border: 3px solid rgb(108, 161, 197);
      -webkit-transform: translate(-200px);
              transform: translate(-200px);
      opacity: 0;
      -webkit-animation: fade-in 1s ease 1 0.5s forwards;
              animation: fade-in 1s ease 1 0.5s forwards;
    }
  
    .home-container h2 {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-top: 250px;
    }
  
    .home-container h2 span {
      font-size: 22px;
      opacity: 0.8;
      padding-bottom: 5px;
      border-bottom: 2px solid var(--color-pri);
      font-family: "Open Sans Condensed", sans-serif;
    }
  
    .home-container h2 p {
      font-size: 18px;
      font-weight: 100;
      font-family: "Open Sans", sans-serif;
    }
  }
  
header {
    height: 80px;
    width: 100%;
    line-height: 100px;
    position: fixed;
    z-index: 999;
    justify-content: center; 
    align-items: center; 
    text-align: center;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation: fade-in 1s ease 1 forwards;
            animation: fade-in 1s ease 1 forwards;
  }
  
  @-webkit-keyframes fade-in {
    0% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
      opacity: 1;
    }
  }
  
  @keyframes fade-in {
    0% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
      opacity: 1;
    }
  }
  
  .navigationContainer {
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center; 
    text-align: center;
    padding-left: 3.8%;
  }

  .headerLogoSection {
    height: 100%;
    overflow: hidden;
  }
  
  .logoText {
    position: relative;
    z-index: 4;
    width: 130px;
    height: 60px;
    font-family: DIN Alternate Bold;
    font-style: italic;
    margin-right: 10%;
    margin-top: 10px;
  }
  
  #headerRow {
    width: 300px;
  }
  
  #headerRow a {
  outline: none;
  text-decoration: none;
  }
  
  .default-color {
    color: rgb(0, 0, 0);
    /* color: white; */
  }
  
  .secondary 
  {
      background-color: #e8e8e8;
      box-shadow: 0px 0px 17px 0px;
      transition: all 0.5s ease-in;
  }
  
  .smallScreenNav {
    display: none;
  }
  
  .navigationContainer nav {
    margin-left: auto;
    margin-right: 5px;
  }
  
  .navigationContainer ul {
    list-style: none;
  }
  
  .navigationContainer ul li {
    display: inline-block;
    cursor: pointer;
  }
  
  .navigationContainer ul li a {
    /* font-family: Trebuchet MS, sans-serif; */
    /* font-family: "Andale Mono", monospace; */
    /* font-weight: bold; */
    font-size: 16px;
    margin-right: 35px;
    padding-bottom: 2px;
    text-decoration: none;
    color: inherit;
    padding: 5px;
  }
  
  .alternate-colorB {
    color: black;
    transition: all 0.7s ease-in;
  }
  
  .navigationContainer ul li a:hover {
    color: #63449c;
    text-decoration: none;
    -webkit-text-decoration-style: none;
            text-decoration-style: none;
    transition: all 0.2s ease-in;
  }

  .languageSection {
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: row;
    padding: 0px;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: row;
  }

  .dropdownLanguage {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(102, 102, 102, 0.543);
  }

  .dropdownLanguage:hover {
    cursor: pointer;
    background-color: rgb(102, 102, 102);
  }
  
  @media (max-width: 938px) {
    .navigationContainer {
      text-align: center;
      justify-content: center; 
      align-items: center; 
      text-align: center;
    }
  
    .smallScreenNav {
      position: absolute;
      display: flex;
      left: 0%;
      margin-left: 20px;
    }
  
    nav {
      display: none;
    }

    .languageSection {
      position: absolute;
      right: 10px;
    }
  
    /* Dropdown Menu */
  
  .dropdown {
    position: absolute;
    top: 65px;
    width: 200px;
    height: 250px;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item {
    background-color: rgba(0, 0, 0, 0.596);
    color: white;
    height: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: var(--border-radius);
    /* transition: background var(--speed); */
    padding: 0.5rem;
  }
  
  a {
    font-family: Trebuchet MS, sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: inherit;
  }
  
  .icon-button {
    border: none;
    background-color: transparent;
    margin-right: 0.5rem;
  }
  
  .actualIcon {
    width: 30px;
    height: 30px;
    fill: black;
  }
  
  .icon-button:hover {
    -webkit-filter: none;
            filter: none;
    cursor: pointer;
  }
  
  .menu-item:hover {
    background-color: #422874;
  }

  @media (max-width: 850px) {
    .languageSection {
      right: 0px;
    }
  }
  }
  
  #about {
      padding-top: 80px;
  }
  
  .about-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 70vh;
    width: 100%;
    padding: 10px 50px 10px 50px;
    overflow: hidden;
    border-radius: 0%;
  }

  .about-container h2 {
    margin-top: 50px;
    font-size: 30px;
    color: black;
    border-bottom: 3px solid #63449c;
    -webkit-animation: fading-in 1s ease 1 forwards;
            animation: fading-in 1s ease 1 forwards;
  }
  
  .about-container p {
    margin-top: 40px;
    line-height: 30px;
    width: 80%;
    text-align: center;
    font-size: 18px;
    color: black;
    -webkit-animation: fading-in 1.25s ease 1 forwards;
            animation: fading-in 1.25s ease 1 forwards;
  }

  @-webkit-keyframes fading-in {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes fading-in {
    0% {
      -webkit-transform: translateY(100px);
              transform: translateY(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @media (max-width: 1236px) {
    .half h2 {
      margin-top: 0%;
    }
  }
  
  @media (max-width: 1084px) {
    .about-container {
        min-height: 70vh;
    }

    .about-container p {
        line-height: 24px;
        font-size: 18px;
    }
  }
  
  @media (max-width: 820px) {
    #about {
      padding-top: 80px;
    }
  
    .about-container {
        min-height: 70vh;
    }

    .about-container p {
        line-height: 20px;
        font-size: 18px;
    }
  }
  
  @media (max-width: 500px) {
    #about {
      padding-top: 80px;
    }

    .about-container {
        min-height: 70vh;
    }

    .about-container p {
        line-height: 16px;
        font-size: 14px;
    }
  }
  
  @media (max-width: 400px) {
    #about {
      padding-top: 80px;
    }
  
    .about-container {
        min-height: 80vh;
    }

    .about-container p {
        font-size: 14px;
    }
  }
  
.serve-container {
    min-height: 80vh;
    width: 100%;
    border-radius: 0%;
    background-color: rgb(92, 168, 255);
    background-image: url(/static/media/serve_background.a2b687dd.jpeg);
    background-size: cover;
    background-position: 50%;
}
  
.serve-container h1 {
    margin: 0 auto;
    font-size: 35px;
  }
  
  .serve-section {
    margin-left: 8%;
  }
  
  .serve-section h2 {
    text-align: left;
    margin-bottom: 15px;
  }

  .serve-section p {
    text-align: left;
    line-height: 25px;
    width: 80%;
  }
  
  .serve-container .screenshot {
    margin: auto 0px;
    position: inherit;
    height: 15%;
    width: 35%;
    /* background-color: rgba(0, 0, 0, 0.322); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease, -webkit-transform 0.2s ease;
    transition: background 0.3s ease, transform 0.2s ease;
    transition: background 0.3s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
    content: url(/static/media/serve_image.138e435a.jpeg);
  }
  
  .serve-container .download {
    position: inherit;
    height: 80px;
    width: 250px;
    background-color: rgba(0, 0, 0, 0.322);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20%;
    margin-bottom: 20px;
    margin-top: 30px;
    align-items: center;
    transition: background 0.3s ease, -webkit-transform 0.2s ease;
    transition: background 0.3s ease, transform 0.2s ease;
    transition: background 0.3s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
  }
  
  .serve-container .download:hover {
    background-color: rgba(255, 255, 255, 0.096);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .serve-container .download .icon {
    position: relative;
    font-size: 40px;
  }
  
  .serve-container .download h2 {
    position: relative;
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    /* font-family: 'Open Sans'; */
    text-align: center;
  }

  @media (max-width: 1020px) {
      .serve-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 80vh;
        margin-top: 100px;
        padding: 10px 10px 50px 10px;
      }

      .serve-section {
        margin-left: 0%;
      }

      .serve-section h2 {
        text-align: center;
        margin-bottom: 15px;
      }
    
      .serve-section p {
        margin: 0px auto;
        text-align: center;
        line-height: 25px;
        width: 90%;
      }

      .serve-container .screenshot {
        height: 25%;
        width: 45%;
      }
  }
  
  @media (max-width: 820px) {
    .serve-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 80vh;
        margin-top: 100px;
        padding: 10px 10px 50px 10px;
      }

      .serve-section {
        margin-left: 0%;
      }

      .serve-section h2 {
        text-align: center;
        margin-bottom: 15px;
      }
    
      .serve-section p {
        margin: 0px auto;
        text-align: center;
        line-height: 25px;
        width: 90%;
      }

      .serve-container .screenshot {
        height: 25%;
        width: 45%;
      }
  }
  
.gallery-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 680px;
    width: 100%;
    padding: 10px 10px 10px 10px;
    overflow: hidden;
    border-radius: 0%;
  }

  .gallery-title-container {
    height: 80px;
    border-bottom: 3px solid #63449c;
  }

  .gallery-title-container h2 {
    margin-top: 30px;
    color: black;
    font-size: 28px;
  }

  .gallery-section {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 600px;
  }

  .images-section {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 720px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    scroll-behavior: smooth;
    padding: 30px 0 0 0;
  }

  .image-container {
    position: relative;
    height: 550px;
    min-width: 40%;
    background-color: rgba(14, 14, 14, 0.285);
    border-radius: 20px;
    margin: 20px 20px 0 0;
  }

  .image-info-gallery {
    width: 100%;
    height: 100px;
    background-color: rgba(44, 44, 44, 0.764);
    bottom: 0;
    position: absolute;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  #img1 {
    background-image: url(/static/media/fuyao.1df2edc7.png);
    background-size: cover;
    background-position: -200px 0px;
  }

  #img2 {
    background-image: url(/static/media/jinko.35ef895a.png);
    background-size: cover;
    background-position: -50px;
  }

  #img3 {
    background-image: url(/static/media/jushi.012e3815.png);
    background-size: cover;
    background-position: -180px 0px;
  }

  #img4 {
    background-image: url(/static/media/hailiang.8948d397.png);
    background-size: cover;
    background-position: -50px 0px;
  }

  #img5 {
    background-image: url(/static/media/minth.b5cf92b6.png);
    background-size: cover;
    background-position: -50px 0px;
  }

  #img6 {
    background-image: url(/static/media/yinlun.08277013.png);
    background-size: cover;
    background-position: -300px 0px;
  }

  #arrow-back {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 200px;
      margin-right: 2%;
  }

  #arrow-forward {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 200px;
    margin-left: 2%;
}

button {
    border: none;
    background: none;
}

.buttonIcon {
    fill: black;
}

.buttonIcon:hover {
    cursor: pointer;
    fill: rgb(150, 30, 150);
}

@media (max-width: 1120px) {
    .image-container {
        height: 550px;
        min-width: 50%;
      }
}

@media (max-width: 1080px) {
    .image-container {
        height: 550px;
        min-width: 60%;
      }
}

@media (max-width: 900px) {
    .image-container {
        height: 550px;
        min-width: 80%;
      }
}

@media (max-width: 672px) {
    .image-container {
        height: 480px;
        min-width: 90%;
      }
}

@media (max-width: 520px) {
    .image-container {
        height: 480px;
        min-width: 100%;
      }
}
.extra-container {
    margin: 40px 0 0 0;
    width: 100%;
    min-height: 40vh;
    background-color: rgba(0, 0, 0, 0.624);
    border-radius: 0%;
    /* background-image: url(../assets/extraBackground.png); */
    background-size: cover;
    background-position: 0px -300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.extra-container p {
    width: 80%;
    text-align: center;
}
.contact-container {
    padding-top: 130px;
    width: 100%;
    min-height: 900px;
    background-color: rgb(76, 76, 76);
    border-radius: 0%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-image: url(/static/media/contact_background.67b70c6b.png);
    background-size: cover;
}

#contact-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

#contact-info {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 400px;
}

#contact-info img {
    width: 110px;
    height: 60px;
    margin: 20px 20px 10px 20px;
}

#contact-info P {
    margin: 0px 20px 10px 20px;
    font-size: 18px;
}

#contact-info P span {
    font-weight: bolder;
    font-size: 20px;
}

form {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

form label {
    margin-bottom: 10px;
    text-align: left;
}

form input {
    font-family: 'Helvetica', Arial, Lucida Grande, sans-serif;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    width: 350px;
    height: 45px;
    color: black;
}

form input:focus {
    outline: none;
    border: 2px solid rgb(42, 228, 76);
}

form textarea {
    font-family: 'Helvetica', Arial, Lucida Grande, sans-serif;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    width: 350px;
    height: 45px;
    color: black;
    resize: vertical;
}

form textarea:focus {
    outline: none;
    border: 2px solid rgb(42, 228, 76);
}

#submitMessage {
    height: 20px;
    text-align: center;
    color: red;
}

#submitMessage span {
    color: rgb(24, 160, 24)
}

form button {
    font-weight: bold;
    margin: 30px auto 20px auto;
    display: flex;
    width: 150px;
    height: 50px;
    background-color: #009541;
    text-align: center;
    align-items: center;
    justify-content: center;
}

form button:hover {
    cursor: pointer;
    background-color: #11512c;
}

@media (max-width: 900px) {
    #contact-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #contact-info {
        justify-content: center;
        align-items: center;
    }

    form button {
        margin-bottom: 50px;
    }
}
.footer-container {
    height: 10px;
    width: 100%;
    border-radius: 0%;
    background-size: cover;
    background-position: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
  .footer-section {
    margin-left: 8%;
  }

  .footer-section p {
    text-align: center;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: black
  }

  .footer-section p a {
      margin-left: 5px;
      color: purple;
  }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  /* background-color: #f1f1f1; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

