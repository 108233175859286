.footer-container {
    height: 10px;
    width: 100%;
    border-radius: 0%;
    background-size: cover;
    background-position: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
  .footer-section {
    margin-left: 8%;
  }

  .footer-section p {
    text-align: center;
    line-height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: black
  }

  .footer-section p a {
      margin-left: 5px;
      color: purple;
  }