header {
    height: 80px;
    width: 100%;
    line-height: 100px;
    position: fixed;
    z-index: 999;
    justify-content: center; 
    align-items: center; 
    text-align: center;
    transform: translateY(0px);
    animation: fade-in 1s ease 1 forwards;
  }
  
  @keyframes fade-in {
    0% {
      transform: translateY(-100px);
      opacity: 1;
    }
  }
  
  .navigationContainer {
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center; 
    text-align: center;
    padding-left: 3.8%;
  }

  .headerLogoSection {
    height: 100%;
    overflow: hidden;
  }
  
  .logoText {
    position: relative;
    z-index: 4;
    width: 130px;
    height: 60px;
    font-family: DIN Alternate Bold;
    font-style: italic;
    margin-right: 10%;
    margin-top: 10px;
  }
  
  #headerRow {
    width: 300px;
  }
  
  #headerRow a {
  outline: none;
  text-decoration: none;
  }
  
  .default-color {
    color: rgb(0, 0, 0);
    /* color: white; */
  }
  
  .secondary 
  {
      background-color: #e8e8e8;
      box-shadow: 0px 0px 17px 0px;
      transition: all 0.5s ease-in;
  }
  
  .smallScreenNav {
    display: none;
  }
  
  .navigationContainer nav {
    margin-left: auto;
    margin-right: 5px;
  }
  
  .navigationContainer ul {
    list-style: none;
  }
  
  .navigationContainer ul li {
    display: inline-block;
    cursor: pointer;
  }
  
  .navigationContainer ul li a {
    /* font-family: Trebuchet MS, sans-serif; */
    /* font-family: "Andale Mono", monospace; */
    /* font-weight: bold; */
    font-size: 16px;
    margin-right: 35px;
    padding-bottom: 2px;
    text-decoration: none;
    color: inherit;
    padding: 5px;
  }
  
  .alternate-colorB {
    color: black;
    transition: all 0.7s ease-in;
  }
  
  .navigationContainer ul li a:hover {
    color: #63449c;
    text-decoration: none;
    text-decoration-style: none;
    transition: all 0.2s ease-in;
  }

  .languageSection {
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: row;
    padding: 0px;
  }

  .dropdown-menu {
    display: flex;
    flex-direction: row;
  }

  .dropdownLanguage {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin: 5px;
    background-color: rgba(102, 102, 102, 0.543);
  }

  .dropdownLanguage:hover {
    cursor: pointer;
    background-color: rgb(102, 102, 102);
  }
  
  @media (max-width: 938px) {
    .navigationContainer {
      text-align: center;
      justify-content: center; 
      align-items: center; 
      text-align: center;
    }
  
    .smallScreenNav {
      position: absolute;
      display: flex;
      left: 0%;
      margin-left: 20px;
    }
  
    nav {
      display: none;
    }

    .languageSection {
      position: absolute;
      right: 10px;
    }
  
    /* Dropdown Menu */
  
  .dropdown {
    position: absolute;
    top: 65px;
    width: 200px;
    height: 250px;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item {
    background-color: rgba(0, 0, 0, 0.596);
    color: white;
    height: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: var(--border-radius);
    /* transition: background var(--speed); */
    padding: 0.5rem;
  }
  
  a {
    font-family: Trebuchet MS, sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: inherit;
  }
  
  .icon-button {
    border: none;
    background-color: transparent;
    margin-right: 0.5rem;
  }
  
  .actualIcon {
    width: 30px;
    height: 30px;
    fill: black;
  }
  
  .icon-button:hover {
    filter: none;
    cursor: pointer;
  }
  
  .menu-item:hover {
    background-color: #422874;
  }

  @media (max-width: 850px) {
    .languageSection {
      right: 0px;
    }
  }
  }
  