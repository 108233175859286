.contact-container {
    padding-top: 130px;
    width: 100%;
    min-height: 900px;
    background-color: rgb(76, 76, 76);
    border-radius: 0%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background-image: url(../assets/contact_background.png);
    background-size: cover;
}

#contact-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

#contact-info {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 400px;
}

#contact-info img {
    width: 110px;
    height: 60px;
    margin: 20px 20px 10px 20px;
}

#contact-info P {
    margin: 0px 20px 10px 20px;
    font-size: 18px;
}

#contact-info P span {
    font-weight: bolder;
    font-size: 20px;
}

form {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

form label {
    margin-bottom: 10px;
    text-align: left;
}

form input {
    font-family: 'Helvetica', Arial, Lucida Grande, sans-serif;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    width: 350px;
    height: 45px;
    color: black;
}

form input:focus {
    outline: none;
    border: 2px solid rgb(42, 228, 76);
}

form textarea {
    font-family: 'Helvetica', Arial, Lucida Grande, sans-serif;
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    width: 350px;
    height: 45px;
    color: black;
    resize: vertical;
}

form textarea:focus {
    outline: none;
    border: 2px solid rgb(42, 228, 76);
}

#submitMessage {
    height: 20px;
    text-align: center;
    color: red;
}

#submitMessage span {
    color: rgb(24, 160, 24)
}

form button {
    font-weight: bold;
    margin: 30px auto 20px auto;
    display: flex;
    width: 150px;
    height: 50px;
    background-color: #009541;
    text-align: center;
    align-items: center;
    justify-content: center;
}

form button:hover {
    cursor: pointer;
    background-color: #11512c;
}

@media (max-width: 900px) {
    #contact-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #contact-info {
        justify-content: center;
        align-items: center;
    }

    form button {
        margin-bottom: 50px;
    }
}