/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  z-index: 1;
}

body {
  background: rgb(212, 212, 212);
  min-height: 100vh;
  font-family: "Inter", sans-serif;
  animation: move 1s linear infinite forwards;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0;
}

:root {
  --color-pri: rgb(82, 188, 250);
  --color-pri-light: rgba(82, 188, 250, 0.445);
  --color-sec: rgb(172, 17, 233);
  --color-sec-light: rgba(172, 17, 233, 0.274);

  --pc-width: 75%;
  --tab-width: 90%;
  --phone-width: 100%;
}

.container {
  border-radius: 10px;
  width: var(--pc-width);
  color: var(--text-pri);
  padding: 10px 40px;
  margin: 5px auto;
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .container {
    width: var(--phone-width);
  }
}

